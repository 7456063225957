<template>
    <button class="btn btn-outline-gray btn-sm" @click="open">
        <i class="fa fa-users" ></i>
        <span v-if='title' > {{title}}</span>
        <!--<span v-else> Nhân viên</span>-->

        <b-modal v-model="listForm" size="xl" content-class="modal-auto" :title="`Nhân viên đơn vị: ${client.name}`" ok-only ok-variant="secondary" ok-title="Đóng" no-close-on-backdrop no-enforce-focus>
            <staff :client-id="client.id"/>
        </b-modal>
    </button>
</template>

<script>
    import Staff from '@/views/setting/roles-and-users/Staff';

    export default {
        props: ["title", "client"],
        components: {Staff},
        data() {
            return {
                listForm: false,
            };
        },
        methods: {
            open() {
                this.listForm = true;
            }
        }
    };
</script>
