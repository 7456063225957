<template>
    <div class="row">
        <div class="col-12" >
            <g-loading v-if="firstLoading" />
            <g-table v-else :filter="filterFormModel" :itemCount="itemCount" :items="items" :fields="fields" @load="load" @reset="reset"
                     title="Đơn vị" titleIcon="fa fa-cubes">
                <template slot="actionBefore">
                    <b-tabs class="c-tabs-button mr-2" small pills v-model="filterFormModel.blocked" @input="load">
                        <b-tab :title="`Tất cả (` +  (Number(params.tabCount[0] ? params.tabCount[0] : 0) + Number(params.tabCount[1] ? params.tabCount[1] : 0)) + `)`"></b-tab>
                        <b-tab :title="`Hoạt động (` +  (params.tabCount[0] ? params.tabCount[0] : 0) + `)`"></b-tab>
                        <b-tab :title="`Tạm khoá (` +  (params.tabCount[1] ? params.tabCount[1] : 0) + `)`"></b-tab>
                    </b-tabs>
                </template>
                <template slot="filter">
                    <dropdown :right-menu="true" type="bordered" icon="fa fa-layer-group" class="mr-2" :model="filterFormModel" attribute="type"  :options="$params.client.typeOptions" :isFilter="true" @change="search" />
                </template>
                <template slot="action">
                    <g-button class="ml-2" v-if="permissions('client_create')" :loading="saveLoading" size="sm" variant="success" icon="plus" content="Thêm mới" @click='create' />
                </template>
                <template #cell(name)="{item}">
                    <div><span class="small text-gray-999">Tên đầy đủ: </span> {{item.name}}</div>
                    <div><span class="small text-gray-999">Tên ngắn gọn: </span> {{item.short_name}}</div>
                    <div v-if='item.email'><span class="small text-gray-999">Email: </span>{{ item.email }}</div> 
                    <div v-if='item.mobile'><span class="small text-gray-999">Số điện thoại: </span>{{ item.mobile }}</div> 
                    <div v-if='params.staffCount[item.id]' >
                        <span class="small text-gray-999">Số nhân viên: </span>
                        {{params.staffCount[item.id] | numFormat}}
                    </div>
                    <div v-if='params.staffCount[item.id]' >
                        <span class="small text-gray-999">Trạng thái: </span>
                        <i v-if="item.blocked" class="small text-danger" >Tạm khoá</i>
                        <i v-else class="small text-success" >Hoạt động</i>
                    </div>
                </template>
                <template #cell(info)="{item}">
                    <div v-if='item.type && $params.client.typeOptions.find(o => o.value === item.type)' >
                        <span class="small text-gray-999">Phân loại: </span>
                        <span class="badge bg-info" >{{$params.client.typeOptions.find(o => o.value === item.type).text}}</span> 
                    </div>
                    <div v-if='params.parents[item.id]'>
                        <span class="small text-gray-999">Đơn vị chủ quản: </span>
                        <span v-for="client_id in params.parents[item.id]" :key="client_id" >
                            <span class="badge bg-gray mr-1" v-if='params.clients[client_id]'>{{params.clients[client_id]}}</span> 
                        </span>
                    </div>
                    <div v-if='item.province_id' >
                        <span class="small text-gray-999">Tỉnh hỗ trợ: </span>
                        <span v-for="province_id in item.province_id" :key="province_id" >
                            <span class="badge bg-primary mr-1" v-if='params.provinces[province_id]'>{{params.provinces[province_id]}}</span> 
                        </span>
                    </div>
                    <!-- <div v-if='item.indicators' >
                         <span class="small text-gray-999">Chỉ số hỗ trợ: </span>
                        <div v-for="code in item.indicators" :key="code" >
                            <i><b>{{code}}</b> <span v-if="$params.indicator[code]">{{$params.indicator[code].name}}</span></i>
                        </div>
                    </div> -->
                </template>
                <template #cell(action)="{item}">
                    <div class="btn-action-3" >
                        <staff v-if="permissions('staff_index')" :client="item" class="ml-1 mb-1" title="" />
                        <g-button v-if="permissions('client_update')" :loading="updateLoading" size="sm" variant="outline-gray" class="ml-1 mb-1" icon="pencil"  @click="update(item.id)" />
                        <g-button  v-if="permissions('client_del')" :loading="delLoading" size="sm" variant="outline-danger" class="ml-1 mb-1" icon="trash-fill" @click='del(item.id)' />
                    </div>
                </template>
            </g-table>
        </div>
        <b-modal v-model="showForm" :title="formTitle" @ok="save" ok-title="Lưu lại" cancel-title="Huỷ" no-close-on-backdrop no-enforce-focus>
            <form-select :disabled='formModel.id' label="Phân loại" :required='true' :model="formModel" attribute="type" :errors="formErrors" :options='$params.client.typeOptions'  />
            <form-input label="Tên đầy đủ" :required='true' :model="formModel" attribute="name"  :errors="formErrors" ></form-input>
            <form-input label="Tên ngắn gọn" :required='true' :model="formModel" attribute="short_name"  :errors="formErrors" ></form-input>
            <div class="row">
                <div class='col-md'>
                    <form-input label="Email" :required='true' :model="formModel" attribute="email"  :errors="formErrors" ></form-input>
                </div>
                <div class='col-md'>
                    <form-input label="Số điện thoại" :required='false' :model="formModel" attribute="mobile"  :errors="formErrors" ></form-input>
                </div>
            </div>
            <div class="row">
                <div class='col-md-12'>
                    <province-select label="Tỉnh/Thành phố"  :required='true'  :model="formModel" attribute="province_id" :multiple="true" :errors="formErrors" :close-on-select='true' />
                </div>
                <div class='col-md-12'>
                    <client-select label="Đơn vị chủ quản" attribute="parents"  :model="formModel" :errors="formErrors" :multiple="true" />
                </div>
                <div class='col-md-12'>
                    <form-select-multiple label="Chỉ số đơn vị hỗ trợ" :model="formModel" attribute="indicators" :errors="formErrors" :options="indicatorOptions" :close-on-select='false'  />
                </div>
            </div>
            <form-checkbox chk-label="Tạm khoá truy cập đơn vị" :model="formModel" :errors="formErrors"  attribute="blocked" />
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button variant="secondary" class="float-right" @click="cancel">Đóng</b-button>
                <b-button :disabled="saveLoading" variant="primary" class="float-right" @click="ok">
                    <b-spinner v-if="saveLoading" small></b-spinner>
                    <em v-else class="fa fa-save" ></em>
                    Lưu lại
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import list from "@/mixins/List";
    import crud from '@/mixins/CRUD';
    import role from '@/mixins/Role';
    import Dropdown from '@/components/Dropdown';
    import ClientSelect from '@/components/ClientSelect';
    import ProvinceSelect from '@/components/ProvinceSelect';
    import Staff from './Staff';

    export default {
        components: {Dropdown, Staff, ClientSelect, ProvinceSelect},
        mixins: [list, crud, role],
        data: function () {
            return {
                service: '/client',
                fields: [
                    {key: 'name', label: "Đơn vị"},
                    {key: 'info', label: "Tình hỗ trợ"},
                    {key: 'action', label: "Hành động", class: 'text-right'},
                ],
                defaultFilterFormModel: {
                    size: 99999,
                    keyword: '',
                    type: null,
                    blocked: 0,
                    parents: [],
                    indicators: []
                },
                defaultFormModel: {
                    type: null,
                    province_id: null
                },
                indicatorOptions: []
            };
        },
        methods: {
            changeType() {
                this.formModel.target_id = null;
            }
        },
        mounted() {
            let indicatorOptions = [];
            for (let code in this.$params.indicator) {
                if (this.$params.indicator[code].indicator !== undefined && this.$params.indicator[code].indicator === false) {
                    continue;
                }
                let indicator = this.$params.indicator[code];
                indicatorOptions.push({value: code, text: code + '. ' + indicator.name});
            }
            this.indicatorOptions = indicatorOptions;
        }
    }
</script>